
import Vue from 'vue';

export default Vue.extend({
  data() {
    return { searchText: '' };
  },
  methods: {
    inputChange(val) {
      this.searchText = val;
      this.$emit('change', val);
    },
  },
});
